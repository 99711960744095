var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": this.$t("cip.desk.notice.title.indexHeadTitle"),
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-remove": _vm.handleDelete, "head-add": _vm.headAdd },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "head-title": this.$t("cip.desk.notice.title.indexHeadTitle"),
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.rowView,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
          "row-publish": _vm.rowPublish,
          "row-view": _vm.rowView,
          "row-del": _vm.rowDel,
          "row-broadcast": _vm.rowBroadcast,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _vm.permission.notice_edit
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: {
                          disabled: row.status == 2,
                          type: "text",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowEdit(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cip.cmn.btn.editBtn")) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: this.$t("cip.desk.notice.title.settingHeadTitle"),
            visible: _vm.settings,
          },
          on: {
            "update:visible": function ($event) {
              _vm.settings = $event
            },
          },
        },
        [
          _c("avue-form", {
            attrs: { option: _vm.selOption },
            model: {
              value: _vm.selForm,
              callback: function ($$v) {
                _vm.selForm = $$v
              },
              expression: "selForm",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.settings = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }